var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"travellers"},[_c('a-row',{attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":24}},[_c('h3',{staticClass:"title"},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_vm._v(" VIAJANTES ")]),_vm._v(" "),_c('a-col',{staticClass:"a-right",attrs:{"span":12}},[(_vm.flight_travellers.length < 10)?_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":function($event){return _vm.addRow(_vm.flight_travellers.length)}}},[_vm._v("\n              Adicionar\n            ")]):_vm._e()],1)],1)],1),_vm._v(" "),_vm._l((_vm.flight_travellers),function(travellers){return _c('a-row',{key:travellers.id,class:`traveller
                          traveller-${travellers.id}`,attrs:{"gutter":[5, 0]}},[_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:_vm.form.getFieldValue(
                  `flight_${_vm.flightSectionID}_person_first_name_${travellers.id}`
                )
                  ? 'filled'
                  : ''},[_vm._v("Nome")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionID}_person_first_name_${travellers.id}`,
                {
                  rules: [
                    {
                      required: _vm.dynamicRequiredField(),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionID}_person_first_name_${travellers.id}`,\n                {\n                  rules: [\n                    {\n                      required: dynamicRequiredField(),\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"travel-input",attrs:{"placeholder":"Nome"}},[_c('template',{slot:"prefix"},[_vm._v("#"+_vm._s(travellers.id))])],2)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('span',{staticClass:"travel-input"},[_c('label',{class:_vm.form.getFieldValue(
                    `flight_${_vm.flightSectionID}_person_last_name_${travellers.id}`
                  )
                    ? 'filled'
                    : ''},[_vm._v("Sobrenome")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `flight_${_vm.flightSectionID}_person_last_name_${travellers.id}`,
                  {
                    rules: [
                      {
                        required: _vm.dynamicRequiredField(),
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `flight_${flightSectionID}_person_last_name_${travellers.id}`,\n                  {\n                    rules: [\n                      {\n                        required: dynamicRequiredField(),\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Sobrenome"}})],1)])],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:_vm.form.getFieldValue(
                  `flight_${_vm.flightSectionID}_person_birthday_${travellers.id}`
                )
                  ? 'filled'
                  : ''},[_vm._v("Nascimento")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionID}_person_birthday_${travellers.id}`,
                {
                  rules: [
                    {
                      required: _vm.dynamicRequiredField(),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionID}_person_birthday_${travellers.id}`,\n                {\n                  rules: [\n                    {\n                      required: dynamicRequiredField(),\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"travel-input",attrs:{"placeholder":"Nascimento","format":"DD/MM/YYYY","disabled-date":_vm.maxDateAge,"showToday":false}})],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('span',{staticClass:"travel-input"},[_c('label',{class:_vm.form.getFieldValue(
                    `flight_${_vm.flightSectionID}_person_CPF_${travellers.id}`
                  )
                    ? 'filled'
                    : ''},[_vm._v("CPF")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"},{name:"decorator",rawName:"v-decorator",value:([
                  `flight_${_vm.flightSectionID}_person_CPF_${travellers.id}`,
                ]),expression:"[\n                  `flight_${flightSectionID}_person_CPF_${travellers.id}`,\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"CPF (opcional)"}})],1)])],1),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',[_c('span',{staticClass:"travel-input"},[_c('label',{class:_vm.form.getFieldValue(
                    `flight_${_vm.flightSectionID}_person_price_${travellers.id}`
                  )
                    ? 'filled'
                    : ''},[_vm._v("Valor (incluindo taxas)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `flight_${_vm.flightSectionID}_person_price_${travellers.id}`,
                ]),expression:"[\n                  `flight_${flightSectionID}_person_price_${travellers.id}`,\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor"},on:{"blur":_vm.calcFlightTotalValue}})],1)])],1),_vm._v(" "),_c('a-col',{staticStyle:{"text-align":"center"},attrs:{"span":1}},[(travellers.id == 1)?_c('a-tooltip',{attrs:{"placement":"bottomRight","title":"Recuperar dados do pagante"}},[_c('a-button',{staticClass:"mt-10",attrs:{"size":"small","type":"primary","shape":"circle","icon":"user"},on:{"click":function($event){return _vm.getPayingTravellerData()}}})],1):_vm._e(),_vm._v(" "),(
              travellers.id === _vm.flight_travellers.length &&
              _vm.flight_travellers.length > 1
            )?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeRow(travellers.id)}}}):_vm._e()],1)],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }