<template>
  <div class="travellers">
    <a-row :gutter="[20, 0]">
      <a-col :span="24">
        <h3 class="title">
          <a-row>
            <a-col :span="12"> VIAJANTES </a-col>
            <a-col class="a-right" :span="12">
              <a-button
                v-if="flight_travellers.length < 10"
                size="small"
                @click="addRow(flight_travellers.length)"
                type="link"
              >
                Adicionar
              </a-button>
            </a-col>
          </a-row>
        </h3>

        <a-row
          v-for="travellers in flight_travellers"
          :key="travellers.id"
          :class="`traveller
                            traveller-${travellers.id}`"
          :gutter="[5, 0]"
        >
          <a-col :span="5">
            <a-form-item>
              <label
                :class="
                  form.getFieldValue(
                    `flight_${flightSectionID}_person_first_name_${travellers.id}`
                  )
                    ? 'filled'
                    : ''
                "
                >Nome</label
              >
              <a-input
                class="travel-input"
                placeholder="Nome"
                v-decorator="[
                  `flight_${flightSectionID}_person_first_name_${travellers.id}`,
                  {
                    rules: [
                      {
                        required: dynamicRequiredField(),
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <template slot="prefix">#{{ travellers.id }}</template>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item>
              <span class="travel-input">
                <label
                  :class="
                    form.getFieldValue(
                      `flight_${flightSectionID}_person_last_name_${travellers.id}`
                    )
                      ? 'filled'
                      : ''
                  "
                  >Sobrenome</label
                >
                <a-input
                  class="travel-input"
                  placeholder="Sobrenome"
                  v-decorator="[
                    `flight_${flightSectionID}_person_last_name_${travellers.id}`,
                    {
                      rules: [
                        {
                          required: dynamicRequiredField(),
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="5">
            <a-form-item>
              <label
                :class="
                  form.getFieldValue(
                    `flight_${flightSectionID}_person_birthday_${travellers.id}`
                  )
                    ? 'filled'
                    : ''
                "
                >Nascimento</label
              >
              <a-date-picker
                class="travel-input"
                placeholder="Nascimento"
                format="DD/MM/YYYY"
                v-mask="'##/##/####'"
                :disabled-date="maxDateAge"
                :showToday="false"
                v-decorator="[
                  `flight_${flightSectionID}_person_birthday_${travellers.id}`,
                  {
                    rules: [
                      {
                        required: dynamicRequiredField(),
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
              </a-date-picker>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item>
              <span class="travel-input">
                <label
                  :class="
                    form.getFieldValue(
                      `flight_${flightSectionID}_person_CPF_${travellers.id}`
                    )
                      ? 'filled'
                      : ''
                  "
                  >CPF</label
                >
                <a-input
                  class="travel-input"
                  placeholder="CPF (opcional)"
                  v-mask="'###.###.###-##'"
                  v-decorator="[
                    `flight_${flightSectionID}_person_CPF_${travellers.id}`,
                  ]"
                >
                </a-input>
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-form-item>
              <span class="travel-input">
                <label
                  :class="
                    form.getFieldValue(
                      `flight_${flightSectionID}_person_price_${travellers.id}`
                    )
                      ? 'filled'
                      : ''
                  "
                  >Valor (incluindo taxas)</label
                >
                <a-input
                  class="travel-input"
                  placeholder="Valor"
                  v-currency
                  @blur="calcFlightTotalValue"
                  v-decorator="[
                    `flight_${flightSectionID}_person_price_${travellers.id}`,
                  ]"
                >
                </a-input>
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="1" style="text-align: center">
            <a-tooltip
              v-if="travellers.id == 1"
              placement="bottomRight"
              title="Recuperar dados do pagante"
            >
              <a-button
                class="mt-10"
                size="small"
                type="primary"
                shape="circle"
                icon="user"
                @click="getPayingTravellerData()"
              >
              </a-button>
            </a-tooltip>
            <a-button
              v-if="
                travellers.id === flight_travellers.length &&
                flight_travellers.length > 1
              "
              size="small"
              type="danger"
              shape="circle"
              icon="delete"
              @click="removeRow(travellers.id)"
            >
            </a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";

export default {
  name: "FlightPersonForm",
  props: {
    contract: Object,
    form: Object,
    flight_travellers: Array,
    flightSectionID: Number,
  },
  data() {
    return {};
  },
  methods: {
    removeRow(id) {
      this.$emit("removeFlightTraveller", id, this.flightSectionID);
    },
    addRow(id) {
      this.$emit("updateFlightTraveller", id, this.flightSectionID);
    },
    getPayingTravellerData() {
      this.form.setFieldsValue({
        [`flight_${this.flightSectionID}_person_first_name_${1}`]: this.form
          .getFieldValue(`client_name`)
          .split(" ")[0],
        [`flight_${this.flightSectionID}_person_last_name_${1}`]: this.form
          .getFieldValue(`client_name`)
          .substr(this.form.getFieldValue(`client_name`).indexOf(" ") + 1),
        [`flight_${this.flightSectionID}_person_birthday_${1}`]:
          this.form.getFieldValue(`client_birthday`),
        [`flight_${this.flightSectionID}_person_CPF_${1}`]:
          this.form.getFieldValue(`client_CPF`),
      });
    },
    dynamicRequiredField() {
      let flag = this.form.getFieldValue("contracted_services");

      if (flag != undefined) {
        flag = this.form
          .getFieldValue("contracted_services")
          .includes("flight");
      } else {
        flag = false;
      }

      return flag;
    },
    calcFlightTotalValue() {
      let totalValue = 0;
      this.flight_travellers.forEach((traveller) => {
        traveller;
        totalValue += parse(
          this.form.getFieldValue(
            `flight_${this.flightSectionID}_person_price_${traveller.id}`
          )
        );
      });

      this.$emit("emitValueUpdate");

      this.form.setFieldsValue({
        [`flight_${this.flightSectionID}_total_price`]: `${totalValue}`.replace(
          ".",
          ","
        ),
      });
    },
    maxDateAge(current) {
      return current && current > new Date();
    },
  },
};
</script>
